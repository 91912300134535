import React, { Component } from "react"
import SEO from "../components/seo"
import $ from "jquery"
import isEmail from "validator/lib/isEmail"
import BgImg from "./../images/contact.svg"
import "./../style/contact.scss"

class Contact extends Component {
  state = {
    name: "",
    email: "",
    subject: "",
    desc: "",
    buttonState: "Submit",
    formValid: false,
  }

  handleChange = (event) => {
    const name = event.target.dataset.name
    const value = event.target.value

    this.setState(
      {
        [name]: value,
      },
      () => {
        this.validateField()
      }
    )
  }

  validateField = () => {
    let nameValid = this.state.name
    let emailValid = isEmail(this.state.email)

    let formValidity = nameValid !== "" && emailValid

    this.setState({
      formValid: formValidity,
    })
  }

  handleSubmit = (event) => {
    event.preventDefault()

    const url =
      "https://docs.google.com/forms/d/e/1FAIpQLSfccxi9t3W2nNRqeW0T7XKwOD-tCPYLJIrp6o9OFQMcf5Kt_A/formResponse"
    const data = {
      "entry.1009933867": this.state.name,
      "entry.319071589": this.state.email,
      "entry.887353431": this.state.subject,
      "entry.1286344984": this.state.desc,
    }

    $.ajax({
      url: url,
      data: data,
      type: "POST",
      dataType: "xml",
      success: function (response, opts) {
        console.log("success")
      },
      failure: function (response, opts) {
        console.log("failure")
      },
    })

    this.setState({
      name: "",
      email: "",
      subject: "",
      desc: "",
      buttonState: "Form Submitted!",
      formValid: false,
    })
  }

  render() {
    return (
      <>
        <SEO title="Contact" />
        <BgImg className="contact-img" />
        <form onSubmit={this.handleSubmit} className="gform">
          <div className="name-email">
            <label htmlFor="name">
              <span className="hidden">Name:</span>
              <input
                name="entry.1009933867"
                id="name"
                data-name="name"
                type="text"
                value={this.state.name}
                onChange={this.handleChange}
                placeholder="Name*"
                required
              />
            </label>
            <label htmlFor="email">
              <span className="hidden">Email:</span>
              <input
                name="entry.319071589"
                data-name="email"
                id="email"
                type="email"
                value={this.state.email}
                onChange={this.handleChange}
                placeholder="Email*"
                required
              />
            </label>
          </div>

          <div className="subject-div">
            <label htmlFor="subject">
              <span className="hidden">Subject:</span>
              <input
                name="entry.887353431"
                data-name="subject"
                id="subject"
                type="text"
                value={this.state.subject}
                onChange={this.handleChange}
                placeholder="Subject"
                required
              />
            </label>
          </div>
          <div className="desc-div">
            <label htmlFor="desc">
              <span className="hidden">Description:</span>
              <textarea
                name="entry.1286344984"
                data-name="desc"
                id="desc"
                value={this.state.desc}
                onChange={this.handleChange}
                placeholder="Description"
                required
              />
            </label>
          </div>
          <button
            className={`prime diff ${
              this.state.buttonState === "Form Submitted!"
                ? "green-state"
                : "disabled"
            }`}
            type="submit"
            disabled={!this.state.formValid}
            value={this.state.buttonState}
          >
            {this.state.buttonState}
          </button>
        </form>
      </>
    )
  }
}

export default Contact
